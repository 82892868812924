/* stylelint-disable */
:global #fade-menu {
  z-index: 1 !important;
}

.OpenedSubmenuHeader,
.Logo {
  padding: 16px 16px 0 16px;
}

.LogoCollapsed {
  padding: 16px 12px 8px 12px;
}

.OpenedSubmenuHeader {
  color: #818c99;
  font-size: 12px;
}

.Unselectable {
  user-select: none;
  height: 100vh;
}

.MenuIcon {
  min-width: fit-content;
}

.Disabled,
.Disabled > * {
  opacity: 0.4;
  cursor: default;
}

.IconContainer {
  width: 24px;
  height: 24px;
  position: relative;
}

.Icon {
  position: absolute;
}

.HiddenIcon {
  opacity: 0;
}

.TransparentIcon {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}

.MenuItem:hover .TransparentIcon {
  opacity: 0;
}

.MenuItem:hover .MenuIcon {
  opacity: 1;
}

.Centering {
  display: flex;
  align-items: center;
}

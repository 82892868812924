.Icon {
    width: 16px;
    height: 16px;
}

.IconSmall {
    width: 12px;
    height: 12px;
}

.IconLarge {
    width: 24px;
    height: 24px;
}

.ProfileInputFieldValue {
    margin-right: 10px;
    font-size: 15px;
    font-family: Inter, sans-serif;
}

.Phone {
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
}

.Error {
    border-color: #f44336;
}

.Phone :global(.PhoneInputCountry) {
    margin-right: 8px;
}

.Phone input {
    font-size: 12px;
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 8px 8px 8px 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* change autocomplete styling trick: */
.Phone input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff inset;
}

html {
  height: 100%;
  box-sizing: border-box;
  overscroll-behavior: none;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100%;
  font-family: Inter, sans-serif;
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: FoxSansPro, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  border: 0;
}

/* stylelint-disable-next-line selector-id-pattern */
#root {
  min-height: 100vh;
}

.RunUI {
  min-height: 100vh;
}

/* TODO: remove legacy overrides 
Legacy Overrides */

/* override sdb styles */
/* stylelint-disable */
.symboldb {
  text-align: left;
}

.symboldb .tree-well {
  width: 25%;
}

.sdbwell {
  position: sticky !important;
  margin-top: 0;
}

.modal-lg {
  width: 900px !important;
}

.sdbform {
  margin-top: 0 !important;
}

.tree-well .well {
  width: 100%;
}

.AlertVpn {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  font-size: 14px !important;
}

.container .tab-page {
  display: block;
}

.Crm {
  display: none;
}

.Cprm {
  display: none;
}

.CrmAdmin {
  width: 100%;
  height: 100%;
}

.CprmAdmin {
  width: 100%;
  height: 100%;
}

.CrmModule .LayoutMain {
  min-width: 1300px;
}